import { Typography } from "@material-ui/core";
import React from "react";
import Layout from "../components/layout";
import {Section} from "../components/section";
import { graphql } from 'gatsby';
import { useTranslation } from "gatsby-plugin-react-i18next";


export default function Ressources() {
  const {t} = useTranslation();
  return (
    <Layout pageTitle={t("About")}>
        <Section title={t("References")}>
          <Typography>
            {t("MSSS")}
          </Typography>
        </Section>
        <Section title={t("Update")}>
          <Typography>
            {t("aboutUpdate")}
          </Typography>
        </Section>
        <Section title={t("Authors")}>
          <Typography>
            Titilayo Tatiana Agbadje<sup>1</sup>, Sabrina Guay-Bélanger<sup>1</sup>, Johanie Lépine<sup>1</sup>, France Légaré<sup>1,2,3</sup>.
          </Typography>
        </Section>
        <Section title={t("Collaborators")}>
          <Typography>
            Chantale Pilon<sup>1</sup>, Angèle Musabyimana<sup>1,2</sup>, Ndeye Thiab Diouf<sup>1,2</sup>, Suélène Georgina Dofara<sup>1</sup>, Tania Lognon<sup>1,2</sup>, Mylène Badeau<sup>2</sup>, Sylvie Langlois<sup>4</sup>, Jean-Claude Forest<sup>2,3</sup>, François Rousseau<sup>2,3</sup>.
          </Typography>
        </Section>
        <Section title={t("No conflict of interest to declare")}>
          <Typography>
            {t("The development of the decision aid")}
          </Typography>
        </Section>
        <Section title={t("Update policy")}>
          <Typography>
            {t("Update planned for 2022")}
            <br />
            <br />
          </Typography>
          <Typography variant="caption">
            <sup>1</sup>VITAM - Centre de recherche en santé durable, centre intégré universitaire de santé et services sociaux de la Capitale-National, Québec-Canada. <sup>2</sup>Université Laval, Québec, QC, Canada. <sup>3</sup>Centre de recherche du CHU de Québec - Université Laval, Québec, QC, Canada. <sup>4</sup>University of British Columbia, Vancouver, BC, Canada.
          </Typography>
          
        </Section>
    </Layout>
  );
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
